.registerForm {
    flex: 1;
    display: flex;   
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    max-height: 300px;
}

.outerContainer {
    flex: 1;
    flex-direction: column;
    width: 400px;
    height: 640px;
    display: flex;
}


.innerContainer {
    flex: 1;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 960px) {
    .outerContainer {
        width: 100%;
        height: 100%;
    }
}

.description {
    margin-top: -16px;
    margin-bottom: 24px;
}

.buttonContainer {
    display: flex;
    align-self: stretch;
    flex-direction: row;
}

.closeButton {
    position: absolute;
    right: 0;
    left: 0;
}

.errorContainer {
    color: red;
    font-size: 12px;
}

.logo {    
    flex: 0 1;
    object-fit: contain;
    max-width: 176px;
    max-height: 176px;
}