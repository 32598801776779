#image_editor_outer_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  user-select: none;
}

#image_editor_inner_container {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: auto;
  align-items: center;
}

#image_editor_image_container {
  position: relative; /* TODO  SAFARI WANTS RESPONSIVE HERE; WHY?*/
}

/* @media (orientation: portrait) { 
    #image_editor_image_container {
        width: 100%;
    }
  }
  @media (orientation: landscape) { 
    #image_editor_image_container {
        height: 100%;
    }
  } */

#image_editor_base_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image_editor_button_container {
  position: absolute;
  bottom: 8px;
  height: 45px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 8px;
  width: calc(100% - 16px);
}

.switch {
  background-color: #dbdbdb;
  border-radius: 4px;
  height: 45px;
  width: 88px;
}

.nextButton {
  position: absolute !important;
  right: 0px;
  bottom: 0px;
  height: 45px;
  width: 100px;
}
