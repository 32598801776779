.loginForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: stretch;
    max-height: 300px;
}

.outerContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 400px;

    height: 640px;
}


@media (max-width: 960px) {
    .outerContainer {
        width: 100%;
        height: 100%;
    }
}

.innerContainer {
    flex: 1;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.description {
    margin-top: -16px;
    margin-bottom: 24px;
}

.buttonContainer {
    display: flex;
    align-self: stretch;
}

.textfield {
    align-self: stretch;
}

.logo {
    flex: 1;
    object-fit: contain;
    max-width: 256px;
    max-height: 256px;
}