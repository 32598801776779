.outerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.loading {
  position: absolute;
  flex: 1;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.text {
  word-wrap: break-word;
  white-space: pre-wrap;
  color: white;
  font-size: 24px;
}