table {
    font-weight: bold 
}

td {
    line-height: 1.5
}

tr {
    text-align: left; 
    vertical-align: center;
}

.outerContainer {
    flex: 1;
    flex-direction: column;
    width: 400px;
    height: 640px;
    display: flex;
}

@media (max-width: 960px) {
    .outerContainer {
        width: 100%;
        height: 100%;
    }
}


.innerContainer {
    flex: 1;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: auto;
}

.logo {
    object-fit: contain;
    max-width: 256px;
    max-height: 256px;
}

.title {

}

.features {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description {
}

.closeButton {
    position: absolute;
    right: 0px;
    top: 0px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    padding: 16px;
}