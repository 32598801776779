.moneyButtons {
  flex: 1;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
}


.outerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.donateContainer {
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;
  justify-content: center;
  max-width: 960px;
}

@media (max-width: 960px) {
  .donateContainer {
    flex-direction: column;
  }
}

.donateText {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 300px;
  text-align: center;
}

.donateHeader {
  text-align: center;
}

.donateLogo {
  flex: 1;
}

.donateInput {
  text-align: center;
}

.donateTeaser {
  flex: 1;
  border-radius: 4px;
  object-fit: contain;
  min-height: 160px;
  align-self: stretch;
}

.leftDonateButtons {
  flex: 1;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightDonateButtons {
  flex: 1;
  max-width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}