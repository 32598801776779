.list_desktop {
    height: 100%;
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: stretch;
    -webkit-transition: width 500ms;
    -moz-transition: width 500ms;
    -o-transition: width 500ms;
    transition: width 500ms;
}

.list_desktop.open {
    width: 30%;
}

.list_desktop.closed {
    width: 0;
}

.list_mobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
