
#container-circles {
  position: absolute;
  bottom: 8px;
  left: 50%;
  margin-left: -36px;
}

#outer-circle {
    height: 72px;
    width: 72px;
    background-color: hsla(0, 0%, 100%, .4);
    z-index: 1
}

#inner-circle,
#outer-circle {
    border-radius: 50%
}

#inner-circle {
    position: relative;
    left: 14px;
    top: 14px;
    height: 44px;
    width: 44px;
    background: #fff;    
    z-index: 2
}

#inner-circle.is-clicked {
    height: 38px;
    width: 38px;
    margin: -19px 0 0 -19px
}

#white-flash.normal {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    -webkit-transition: opacity .9s ease-out;
    -o-transition: opacity .9s ease-out;
    transition: opacity .9s ease-out
}

#white-flash.do-transition {
    opacity: 0;
    background: #fff
}

#display-error {
    color: #000;
    background-color: #fff
}

.react-html5-camera-photo {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    overflow: auto;
}

.react-html5-camera-photo>video {
    max-height: 100%;
    max-width: 100%;
}


.react-html5-camera-photo>.display-error {
    margin: 0 auto,
   
}



.react-html5-camera-photo>video,.react-html5-camera-photo>image {
  object-fit: contain;
}

@media only all and (max-width: 960px){
    .react-html5-camera-photo>video,.react-html5-camera-photo>image {
        max-width: 100%;
        max-height: 100%;
    }
}

/*# sourceMappingURL=index.css.map*/