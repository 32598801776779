.App {
  text-align: center;
}

.App-logo {
  max-width: '50%';
  max-height: '50%';
  margin-bottom: 24px;
}

.bubble_small, .bubble_medium, .bubble_large, .name, .heart {
  opacity: 0;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: show;
  pointer-events: none;
}

.bubble_small {
  animation-delay: 0.5s;
}

.bubble_medium {
  animation-delay: 1s;
}

.bubble_large {
  animation-delay: 1.5s;
}

.name {
  animation-delay: 2s;
}

.heart {
  animation-delay: 2.5s;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes show {
  0% { opacity: 0; }
  100% { opacity: 1; }
} 

html, body, #root, #app, #app>div {
  width: 100%;
  height: 100%;
}

