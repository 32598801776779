.outerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emojis {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.innerContainer {
  flex: 1;
  display: flex;
  margin: 8px 4px;
  flex-direction: row;
  width: calc(100% - 8px);
}

.actions {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 8px;  
}

.action {
  margin-right: 8px !important;
}

.avatar {
  height: 48px;
  width: 48px;
  border-radius: 4px;
  background-color: lightgray;
  object-fit: cover;
}

.header {
  margin: 4px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100% - 48px);
  min-height: 32px;
}
.creator {
  font-weight: 600;
  flex: 1;
}

.createdDate {
  font-style: italic;
}

.footer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; 
}

.text {
  flex: 1;
  margin: 4px;
}

.imageContainer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}