.ir_outer_container {
  flex: 1;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ir_content_container {
  position: relative;
}

.ir_image {
  flex: 1;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.ir_select_container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  bottom: 8px;
  width: calc(100% - 16px);
  left: 8px;
}

.ir_select {
  max-width: 400px;
  background-color: #dbdbdb;
  border-radius: 4px;
  margin-right: 8px;
}

.ir_chip_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.ir_upload_button {
  height: 45px;
  width: 100px;
}
